<template>
  <div v-if="edition || src" v-lazy-container="{ selector: nolazy ? null : 'img' }">
    <img class="card-img-top hand-pointer" :class="{'card-img-cover': !full}" :key="'edition_image_' + imgId"
         width="1" height="1"
         v-if="cachedImage"
         :id="'edition_image_' + imgId"
         :data-src="cachedImage"
         :data-error="cachedImage || edition.lowResImg"
         :src="nolazy ? cachedImage : '' "
         @click="() => showImg()"
         alt="edition-image">

    <vue-easy-lightbox
      :visible="visible"
      :imgs="[cachedImage || edition.lowResImg]"
      :index="0"
      @click="handleHide"
      @hide="handleHide"
    ></vue-easy-lightbox>

  </div>
</template>

<script>

  import imgixClient from '../../services/imgixClient';
  import { cachefetch } from '../../services/workers/cachefetch.worker';
  import VueEasyLightbox from 'vue-easy-lightbox';
  import * as _ from 'lodash';

  // When images are less than this we use the original
  const MAX_SIZE_FOR_ORIGINAL = 1024 * 1024 * 10;

  export default {
    name: 'editionImage',
    props: ['edition', 'id', 'src', 'full', 'nolazy'],
    components: {VueEasyLightbox},
    data() {
      return {
        visible: false,
        cachedImage: ''
      };
    },
    created() {
      cachefetch(this.image, 'blob').then(imgBlob => {
        this.cachedImage = URL.createObjectURL(imgBlob)
      })
    },
    computed: {
      image() {

        if (!this.edition) {
          return;
        }

        // Try work out type based on additional metadata
        if (this.edition && this.edition.attributes && this.edition.attributes.asset_type) {
          const isNewFormat = _.indexOf(['image/svg+xml', 'image/webp', 'video/webm'], this.edition.attributes.asset_type) > -1;
          if (isNewFormat) {
            return this.edition.lowResImg;
          }
        }

        // Check if image is small enough to render directly
        if (this.isSmallEnoughToDisplay()) {
          //console.log(`Edition is small enough - size [${this.edition.image_metadata['Content-Length']}] - using original`);
          return this.edition.lowResImg;
        }

        return imgixClient.buildURL(this.edition.lowResImg, {
          //format - For browsers that support it, converts the image to the WebP format for better compression.
          //compress - It will turn those GIFs into an animated WebP in supported browsers (such as Chrome)
          auto: 'format,compress',
          q: 85, // reduces the image quality slightly to improve compression (default is 75).
          'gif-q': 95, // reduces the image quality of GIFS - the higher the number, the more compression applied to the GIF.
          'fit': 'max', // set a max size
          'w': 1000, // max width
          'h': 1000 // max height
        });
      },
      imgId() {
        if (this.id) {
          return this.id;
        }
        // generate random one
        return Math.floor(Math.random() * Math.floor(1000));
      }
    },
    methods: {
      isSmallEnoughToDisplay() {
        if (this.edition && _.has(this.edition, 'image_metadata["Content-Length"]')) {
          const contentLength = this.edition.image_metadata['Content-Length'];
          return contentLength < MAX_SIZE_FOR_ORIGINAL;
        }
        return false;
      },
      showImg() {
        this.visible = true;
      },
      handleHide() {
        this.visible = false;
      }
    }
  };
</script>

<style scoped lang="scss">
  .v-lazy-image {
    filter: blur(0px);
    transition: filter 0.5s;
  }

  .v-lazy-image-loaded {
    filter: blur(0);
  }

  .card-img-cover {
    object-fit: cover;
    height: 20rem;
    object-position: top;
  }

  .vel-modal {
    background: rgba(0, 0, 0, 0.80) !important;
  }

</style>
